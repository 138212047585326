
.Card-section {
  width: 100%;
  margin: 20px 0;
}

#cards {
  padding: 20px;
}

.Card {
  width: 100%;
  cursor: pointer;
}

.Card-selection {
  display: flex;
  flex-direction: row;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-1deg); }
  20% { transform: rotate(1deg); }
  30% { transform: rotate(0deg); }
  40% { transform: rotate(1deg); }
  50% { transform: rotate(-1deg); }
  60% { transform: rotate(0deg); }
  70% { transform: rotate(-1deg); }
  80% { transform: rotate(1deg); }
  90% { transform: rotate(0deg); }
  100% { transform: rotate(-1deg); }
}
