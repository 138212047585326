
.Player-section {
  width: 100%;
  margin: 20px 0;
}

.Player {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Team-circle {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: auto 5px;
}

.Game-log {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Game-over {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: lawngreen;
}
