
.Settings-content {
  display: flex;
  padding: 10px;
}

.Volume-slider {
  width: 250px;
  margin: 0;
}
