
.Rules-content {
  height: 90%;
  width: 800px;
}

.Jack-titles {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Jack-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
}

.Jack-card {
  width: 150px;
  flex: 1;
  margin: 5px 10px 20px;
}
