
.Game-panel {
  color: aliceblue;
  background-color: #5e5e5e;
  height: calc(100vh - 40px);
  width: 80%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
