.Main {
  background-color: #20232a;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Home-content {
  background-color: #474d50;
  height: 70vh;
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.App-logo {
  max-height: 60%;
}

.Home-input {
  background-color: #cec7ad;
  border-radius: 10px;
}
